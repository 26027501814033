@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
.profilPage {
  h2 {
    margin-bottom: 0;
  }
  .title {
    width: 100%;
    border-bottom: 0.5px solid #c1c1c1;
    h1 {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 22px;
      color: #0c4292;
      margin-bottom: 0;
      margin: 16px 0 16px 32px;
    }
  }
  .formProfil {
    display: flex;
    justify-content: space-around;
    padding: 1.5rem 2rem;
    &_left {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .profilImage {
        margin-bottom: 0.5rem;

        .imgTag {
          box-sizing: border-box;
          position: relative;
          width: 250px;
          height: 250px;
          object-fit: cover;
          border-radius: 50%;
          overflow: hidden;
          .hoverImage {
            z-index: 2;
            width: 100%;
            border-radius: 50%;
            height: 100%;
            display: flex;
            transition: 0.5s;
            justify-content: center;
            // transform: scale(0);
            opacity: 0;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.7);
            left: 0%;
            top: 0;
            position: absolute;
          }
          &:hover .hoverImage {
            // transform: scale(1);
            opacity: 1;
          }
        }

        input {
          display: none;
        }
      }
      .userName {
        margin-bottom: 0.2rem;
        font-size: 1.3rem;
        h2 {
          color: #0c4292;
        }
      }
      .subjectName {
        padding: 0rem 1rem;
        border: 1px solid var(--statusUndefined);
        border-radius: 1.5rem;
        font-size: 0.8rem;
        div {
          p {
            font-family: Roboto;
            font-weight: normal;
            font-size: 1.4rem;
          }
        }
      }
    }
    &_right {
      padding: 0.5rem;
      width: 40%;
      &_center {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .phoneNumber {
          width: 45%;
        }
        .dateBirth {
          width: 45%;
          .datePicke {
            width: 100%;
          }
        }
        .gender {
          width: 45%;
        }
        .email {
          width: 45%;
        }
        .region {
          width: 45%;
        }
        .district {
          width: 45%;
        }
      }
      .subject {
        width: 100%;
      }
      .submitBTN {
        width: 100%;
        button {
          margin-left: auto;
        }
      }
    }
  }
}
