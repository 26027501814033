@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.navbar {
  min-width: 327px;
  width: 327px;
  height: calc(100vh - 75px);
  background-color: white;
  background: #ffffff;
  box-shadow: 4px 0px 15px rgba(152, 152, 152, 0.25);
  padding-top: 25px;
  position: sticky;
  z-index: 0;
  top: 75px;
  transition: 0.5s;
  overflow-x: hidden;
  .navbarImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 50%;
      object-fit: cover;
      transition: 0.5s;
    }
  }
  &.active {
    min-width: 95px !important;
    width: 95px !important;
    .navbarImage {
      margin-bottom: 1.6rem;
      img {
        width: 2.2rem;
        height: 2.2rem;
      }
    }

    .navbar_menu {
      ul > li {
        opacity: 0;
      }
    }
  }
  .teacherName {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
    // transition: 0.5s;
    position: relative;
    margin-bottom: 10px;
    h1 {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      padding: 0;
      color: #123597;
    }
    p {
      font-weight: 300;
      font-size: 20px;
      color: #123597;
    }
  }
  .teacherName::after {
    content: "";
    width: 80%;
    position: absolute;
    bottom: 0;
    height: 0.5px;
    background-color: #c1c1c1;
  }
  &_menu {
    width: 327px;
    position: relative;
    cursor: pointer;
    min-height: 75px;
    display: flex;
    align-items: center;
    // justify-content: center;
    // font-family: 'Roboto', sans-serif;

    padding-left: 34px;
    &.active {
      background-color: rgba(12, 66, 146, 0.03);
      ul {
        svg {
          path {
            fill: var(--toryBlue);
          }
        }
        li {
          color: var(--toryBlue);
        }
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 75px;
        width: 8px;
        background: var(--toryBlue);
        border-radius: 0px 7px 7px 0px;
        transform: translateX(0);
      }
    }
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      li {
        transition: 0.5s;
        list-style-type: none;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        font-family: Roboto;

        margin-left: 13px;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.03em;

        color: #949494;
      }
    }
  }
  &_menu:hover {
    background-color: rgba(12, 66, 146, 0.03);
    // &::before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   height: 75px;
    //   width: 8px;
    //   background: #0c4292;
    //   border-radius: 0px 7px 7px 0px;
    //   transform: translateX(0);
    // }
  }
}
// @media (max-width: 1566px) {
//   .navbar {
//     top: 75px * 0.71;
//     width: 327px * 0.71;
//     padding-top: 25px * 0.71;
//     height: calc(100vh - 75px * 0.71);
//     &.active {
//       width: 95px * 0.71 !important;
//     }

//     &_menu {
//       min-height: 75px * 0.71;
//       padding-left: 34px * 0.71;
//       &.active {
//         &::before {
//           height: 75px * 0.71;
//           width: 8px * 0.71;
//         }
//       }
//       ul {
//         li {
//           font-size: 20px * 0.71;
//           margin-left: 13px * 0.71 !important;
//         }
//       }

//       svg {
//         width: 32px * 0.71;
//       }
//     }
//     // &_menu:hover {
//     //   &::before {
//     //     height: 75px * 0.71;
//     //     width: 8px * 0.71;
//     //   }
//     // }
//   }
// }
