.startTestModal {
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .count {
      color: var(--label-color);
      font-size: 0.8rem;
    }
  }
  .startTestForm {
    .ant-row.ant-form-item {
      margin-bottom: 1rem;
    }
    .label {
      font-family: Roboto;
      color: var(--label-color);
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 0.2rem;
    }
    .mini_input {
      width: 5.2rem;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .subjects {
      margin-bottom: 1rem;
      &_name {
        color: var(--primaryBlue);
        font-size: 1.1rem;
        font-weight: normal;
        span {
          font-family: Roboto !important;
        }
      }
    }
    .groups {
      .ant-row {
        margin-bottom: 0;
      }
      .label_lg {
        color: var(--primaryBlue);
        font-family: Roboto;
        font-size: 1.1rem;
      }
      .label_sm {
        color: var(--label-color);
        font-family: Roboto;
        font-size: 0.8rem;
        margin-bottom: 0.25rem;
      }
    }
  }

  .submit_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }
}
