.universal_header {
  height: 70px;
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--cornflowerBlue);
  .backBtn {
    display: flex;
    align-items: center;
    padding: 0.2rem 0.6rem;
    margin-right: 1rem;
    border-radius: var(--button-radius);
  }
  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: var(--toryBlue);
    margin-bottom: 0;
  }
  > div {
    display: flex;
    align-items: center;
    margin-left: auto;
    > button {
      margin-left: 2rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
