.students {
  display: flex;
  flex-direction: column;
  &_table {
    .status {
      .topilmadi {
        color: var(--statusUndefined);
      }
      .online {
        color: var(--statusOnline);
      }
      .offline {
        color: var(--statusOffline);
      }
    }
    .more {
      &_icon {
        margin: 0 auto;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        transition: 0.2s;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background: var(--hoverLightBlue);
        }
      }
    }
  }

  .studentsTab {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
  .attachedTests {
    .ant-table-row {
      > td {
        padding-left: 2rem;
      }
    }
    .ant-table-thead > tr > th {
      padding-left: 2rem;
    }
    .status {
      padding: 0.2rem 0.7rem;
      border-radius: 0.25rem;
      color: var(--white);
      &.working {
        background: #f8d044;
      }
      &.not_working {
        background: #aaaaaa;
      }
      &.all_worked {
        background: #06d6a0;
      }
    }
    .test_time {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        border-right: 0.05rem solid rgba(0, 0, 0, 0.5);
        padding-right: 0.4rem;
        margin-right: 0.4rem;

        p {
          &:not(:last-child) {
            margin-bottom: 0.2rem;
          }
        }
      }
    }
    .more {
      &_icon {
        margin: 0 auto;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        transition: 0.2s;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background: var(--hoverLightBlue);
        }
      }
    }
    .ant-table-row {
      cursor: pointer;
    }
  }
}

.students_table {
  .ant-table-row {
    > td {
      padding-left: 2rem;
    }
  }
  .ant-table-thead > tr > th {
    padding-left: 2rem;
  }
}

.testingStudents {
  .ant-table-row {
    > td {
      padding-left: 2rem;
    }
  }
  .ant-table-thead > tr > th {
    padding-left: 2rem;
  }
}

.studentsForm {
  .ant-row {
    margin-bottom: unset;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    .blue_ant_button {
      margin-left: auto;
    }
  }
  .disabled_input {
    .ant-input {
      background: initial;
      color: initial;
    }
  }
  .delete {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    &_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .newGroup {
    cursor: pointer;
    margin-bottom: 1rem;
    font-size: 1rem;
    text-decoration: underline;
    transition: 0.2s;
    &:hover {
      color: var(--ant-primary-color);
    }
  }
  .checkIcon {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 !important;
    .ant-btn {
      border-radius: 0.25rem;
      padding: 0;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 100%;
        width: 100%;
      }
      .ant-spin {
        display: flex;
        align-items: center;
      }
    }
  }
}

.studentHeader {
  display: flex;
  align-items: center;
  & > * {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
  .startTest {
    display: flex;
    align-items: center;
    height: 2.3rem;
    border-radius: var(--button-radius);
    svg {
      path {
        stroke: var(--ant-primary-color);
      }
    }
  }
}
.sharePopover {
  width: 20rem;
  .ant-popover-inner {
    border-radius: 0.5rem;
  }
  .ant-popover-inner-content {
    padding: 0.5rem 0rem !important;
  }
  .ant-input {
    width: 18rem;
    margin: 0 1rem;
    background-color: initial !important;
    color: initial !important;
    cursor: initial !important;
  }
  p {
    color: var(--toryBlue);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.6rem 1rem;
    cursor: pointer;
    &:hover {
      background: var(--hoverLightBlue);
    }
  }
  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

.testingStudents {
  .white_blue_btn {
    height: 38px;
  }
  .status {
    .Bajarmadi {
      color: #e84c4c;
    }
    .Bajarmoqda {
      color: #f8d044;
    }
    .Tugalladi {
      color: #06d6a0;
    }
  }
}

.countdownSpin {
  display: inline-flex !important;
  align-items: center;
  .ant-spin-dot-item {
    background-color: var(--white);
  }
}
