.header {
  width: 100%;
  position: sticky;
  top: 0;
  height: 75px;
  background-color: #ffffff;
  box-shadow: 4px 0px 15px rgba(152, 152, 152, 0.25);
  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  z-index: 2;

  &_left {
    // width: 260px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hamburger {
      margin-right: 1rem;
    }
    .logo {
      h1 {
        color: var(--primaryBlue);
        margin: 0;
        font-size: 1.5rem;
      }
    }
  }
  .ant-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user_img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
}
.user_popover {
  width: 16rem;
  .ant-popover-inner {
    border-radius: 0.5rem;
  }
  .ant-popover-inner-content {
    padding: 0.5rem 0 !important;
  }
  &_content {
    p {
      display: flex;
      align-items: center;
      color: var(--toryBlue);
      font-weight: 500;
      font-size: 1rem;
      padding: 0.6rem 1rem;
      cursor: pointer;
      &:hover {
        background: var(--hoverLightBlue);
      }
      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.4rem;
        path {
          stroke: var(--primaryBlue);
          stroke-width: 3 !important;
        }
      }
      span {
        line-height: 0.9;
      }
    }
  }
}
