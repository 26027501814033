.numberOfTests {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-right: 36px;
  // color: #0c4292;
  color: var(--toreaBay);
}

.tabsTests {
  .ant-tabs-nav {
    padding-left: 2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0 !important;
    /* identical to box height */

    color: #777777;
  }
}
