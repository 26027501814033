.myGroup {
  display: flex;
  flex-direction: column;
  .extraRow {
    display: flex;
    align-items: center;
    background-color: var(--havelockBlue);
    color: var(--white);
    height: 4.5rem;
    .count {
      font-weight: 500;
      font-size: 1rem;
      margin-left: 6rem;
    }
    .moreActions {
      display: flex;
      margin-left: auto;
      .white_btn {
        margin-right: 2rem;
      }
      .cancelBtn {
        margin-left: auto;
        padding: 0.5rem;
        margin-right: 4rem;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  &_table {
    .more {
      &_icon {
        margin: 0 auto;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        transition: 0.2s;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover {
          background: var(--hoverLightBlue);
        }
      }
    }
    .start_test {
      button {
        margin: 0 auto;
      }
    }
    .ant-table-row {
      cursor: pointer;
    }
    .ant-table-row-selected {
      td {
        background-color: var(--hoverLightBlue);
      }
    }
  }
}

.more_popover {
  width: 14rem;
  .ant-popover-inner {
    border-radius: 0.5rem;
  }
  .ant-popover-inner-content {
    padding: 0.5rem 0 !important;
  }
  &_actions {
    p {
      color: var(--toryBlue);
      font-weight: 500;
      font-size: 1rem;
      padding: 0.6rem 1rem;
      cursor: pointer;
      &:hover {
        background: var(--hoverLightBlue);
      }
    }
  }
}

.groupCreateEditForm {
  .ant-form-item {
    margin-bottom: unset;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    .blue_ant_button {
      margin-left: auto;
    }
  }
}
