.loginCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/loginBg.jpg);
  background-size: cover;
  background-position-y: center;
  &_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    background: var(--white);
    box-shadow: 0px 85px 186px rgba(188, 188, 188, 0.35),
      0px 25.625px 56.0736px rgba(188, 188, 188, 0.25),
      0px 10.6433px 23.2901px rgba(188, 188, 188, 0.2),
      0px 3.84948px 8.42357px rgba(188, 188, 188, 0.15);
    border-radius: 0.6rem;
    padding: 0 2rem;
    .ant-form {
      width: 100%;
    }
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background: var(--primaryBlue);
      span {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--white);
      }
    }
    .name {
      font-size: 1rem;
      font-weight: bold;
      color: var(--primaryBlue);
      margin-bottom: 2rem;
    }
    .loginForm {
      .verification_code {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .blue_ant_button {
        margin-left: auto;
      }
    }
  }
}
