@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
:root {
  --primaryBlue: #0c4292;
  --statusUndefined: #b5b5b5;
  --statusOnline: #06d6a0;
  --statusOffline: #e84c4c;
  --cornflowerBlue: #c1c1c150;
  --toryBlue: #0e5cad;
  --toreaBay: #123597;
  --havelockBlue: #6192dc;
  --white: #ffffff;
  --scorpion: #606060;
  --athensGray: #f2f2f7;
  --chateauGreen: #35b956;
  --baliHai: #909fb7;
  --sunsetOrange: #f54848;
  --caribbeanGreen: #06d6a0;
  --hoverLightBlue: #276ace40;
  --comet: #59597c50;
  --tundora: #4d4d4d;
  --inputBg: #f4f4f4;
  --black: #000000;
  --button-radius: 0.32rem;
  --label-color: #7c7c7c;
}
p {
  margin-bottom: 0;
}
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-thumb {
  background: var(--toryBlue);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}
body {
  background-color: var(--athensGray);
}
.flex {
  display: flex;
}
.component_container {
  background: #ffffff;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  width: 100%;
  margin: 25px;
  transition: 0.5s;
}

.blue_ant_button {
  border-radius: var(--button-radius);
  height: 2.3rem;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 0.5rem;
  }
}
.white_btn {
  border-radius: var(--button-radius);
  height: 2.3rem;
  display: flex;
  align-items: center;
  background: var(--white);
  border-color: var(--white);
  color: var(--ant-primary-color);
  &:hover,
  &:focus {
    background: var(--white);
    border-color: var(--white);
    color: var(--ant-primary-color);
  }
  svg {
    margin-right: 0.5rem;
    path {
      stroke: var(--ant-primary-color);
    }
  }
}
.green_btn {
  border-radius: var(--button-radius);
  height: 2.3rem;
  display: flex;
  align-items: center;
  background: var(--chateauGreen);
  border-color: var(--chateauGreen);
  &:hover,
  &:focus {
    background: var(--chateauGreen);
    border-color: var(--chateauGreen);
  }
  svg {
    margin-right: 0.5rem;
  }
}
.white_blue_btn {
  color: var(--ant-primary-color);
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  background: var(--white);
  border-color: var(--ant-primary-color);
  &:hover {
    background: var(--white);
    border-color: var(--ant-primary-color);
  }
  &:focus {
    background: var(--white);
    border-color: var(--ant-primary-color);
  }
  svg {
    margin-right: 0.5rem;
  }
}

.search {
  background: var(--inputBg);
  border-radius: var(--button-radius);
  height: 2.5rem;
  width: 15rem;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  .suffix {
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.2s;
    &:hover {
      background: var(--cornflowerBlue);
    }
    svg {
      padding: 0.2rem;
      width: 100%;
      height: 100%;
      path {
        stroke: var(--black);
      }
    }
  }
}

table tr td.ant-table-selection-column,
table tr th.ant-table-selection-column {
  padding-left: 2rem;
}

.ant-table-thead tr th {
  color: var(--primaryBlue);
  font-size: 0.88rem;
  font-weight: normal;
}
.ant-table-tbody tr td {
  color: var(--primaryBlue);
  font-size: 1.1rem;
  font-weight: 500;
}

.ant-btn {
  &::before {
    top: -2px !important;
  }
}
// .auto_search {
//   background: #f4f4f4;
//   border-radius: 30px;
//   height: 38px;
//   margin-right: 30px;
//   width: 220px;
//   display: flex;
//   align-items: center;
// }

.ant-table-body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background: transparent;
  position: absolute;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #cccc;
  border-radius: 0.5rem;
}

.ant-modal-content {
  border-radius: 0.5rem;
  overflow: hidden;
}

.ant-input {
  border-radius: 0.25rem;
}

.ant-tabs-nav {
  padding-left: 2rem;
}

.custom_Select {
  .ant-select-selector {
    border-radius: 4px !important;
    border: 0.5px solid var(--ant-primary-color) !important;
    // .ant-select-selection-search {
    // }
    .ant-select-selection-placeholder {
      color: var(--ant-primary-color) !important;
    }
  }
}
.custom_Select.ant-select-focused {
  .ant-select-selector {
    border: 0.5px solid var(--ant-primary-color) !important;
    .ant-select-selection-placeholder {
      color: var(--ant-primary-color) !important;
    }
  }
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.border-5 {
  border-radius: 5px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem;
  .pageSize {
    display: flex;
    align-items: center;
    margin-right: 2rem;
    p {
      margin-bottom: 0;
    }
    .ant-select-selector {
      border: none;
    }
    .ant-select-focused {
      .ant-select-selector {
        box-shadow: unset !important;
      }
    }
  }
}

// @media (max-width: 1566px) {
//   .component_container {
//     width: calc(100% - 327px * 0.71);
//   }
//   .navbar.active ~ .component_container {
//     width: calc(100% - 95px * 0.71);
//   }
// }
