.more_actions_popover.ant-popover {
  .ant-popover-inner-content {
    padding: 5px 0;
  }
}
.popoverEditTest {
  border-radius: 7px;

  p {
    padding: 13px 126px 13px 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0c4292;
    cursor: pointer;
    &:hover {
      background: rgba(39, 106, 206, 0.25);
    }
  }
}

.myTests {
  .more {
    &_icon {
      margin: 0 auto;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      transition: 0.2s;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
      &:hover {
        background: var(--hoverLightBlue);
      }
    }
  }
  .green_btn {
    margin: 0 auto;
  }
  .editTests {
    margin: 0 auto;
  }
  .filtr {
    display: flex;
    height: 46px;
    align-items: center;
    padding-left: 2rem;
    border-bottom: 0.5px solid #f0f0f0;

    p {
      font-size: 14px;
      color: #0c4292;
      padding-left: 20px;
    }
    .selectedSubject {
      padding: 3px 15px;
      border-radius: 15px;
      background-color: var(--cornflowerBlue);
      display: flex;
      align-items: center;
      .icon {
        cursor: pointer;
        margin-left: 7px;
        font-weight: normal;
        font-size: 1.2rem;
        color: black;
      }
      .subject_name {
        font-weight: 500;
        font-size: 1rem;
      }
    }
    .filter_themes {
      margin-left: 10px;
      padding: 5px;
      cursor: pointer;
      border-radius: 50%;
      svg {
        transform: translateY(3px);
      }
      &:hover {
        background-color: var(--ant-primary-1);
      }
    }
  }
  .extraRow {
    display: flex;
    align-items: center;
    background-color: var(--havelockBlue);
    color: var(--white);
    height: 4.5rem;
    .count {
      font-weight: 500;
      font-size: 1rem;
      margin-left: 6rem;
    }
    .moreActions {
      display: flex;
      margin-left: auto;
      .white_btn {
        margin-right: 2rem;
      }
      .cancelBtn {
        margin-left: auto;
        padding: 0.5rem;
        margin-right: 4rem;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  .editTests {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background: #ebf1f7;
    }
  }
}
.virtualExam {
  display: flex;
  > div {
    width: 50%;
    box-sizing: border-box;
    .virtualExam_title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        > p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          display: flex;
          align-items: center;
          letter-spacing: 0.15px;
          color: var(--ant-primary-color);
          margin-left: 9px;
        }
      }
    }
    .virtualExam_list {
      > div {
        display: flex;
        justify-content: space-between;
        margin: 6px 0;
        > p {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: var(--tundora);
          display: inline-block;
        }
        > span {
          position: relative;
          bottom: 5px;
          border-bottom: 1px dashed #000000;
          display: block;
          width: 100%;
          margin: 0 5px;
        }
      }
    }
    .virtualExam_time {
      > h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        margin-top: 26px;
        margin-bottom: 13px;
        color: var(--primaryBlue);
      }
      > div {
        display: flex;
        margin-bottom: 18px;
        > div {
          margin-right: 27px;
          > p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: var(--primaryBlue);
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .compulsory_sciences {
    padding: 26px 60px 0 34px;
    .science_list {
    }
  }
  .elective_sciences {
    padding: 26px 60px 26px 60px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 33px;
      left: 0;
      height: 86%;
      border-left: 0.5px solid var(--comet);
    }
  }
}
.virtualExam_title_h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: var(--ant-primary-color);
  margin-bottom: 0;
}
.Mswiper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--athensGray);
  > span {
    background: var(--white);
    border: 0.5px solid var(--statusUndefined);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    width: 28px;
    height: 28px;
  }
  .swiper-container {
    width: 92%;
    .swiper-wrapper {
      .swiper-slide {
        width: auto;
        > button.active {
          background: var(--ant-primary-color);
          color: var(--white);
        }
        > button {
          width: 60px;
          height: 32px;
          cursor: pointer;
          background: var(--athensGray);
          border: 1px solid var(--statusUndefined);
          border-radius: 17px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

.subjects_popover {
  h3 {
    padding: 5px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;

    line-height: 22px;
    margin: 0 !important;
    color: var(--tundora);
    border-bottom: 1px solid var(--tundora);
  }
  .subjectsList {
    max-height: 300px;
    overflow-y: auto;
    .subject {
      margin: 5px 0;
      padding: 3px 0;
      cursor: pointer;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      color: var(--ant-primary-color);
    }
    :hover {
      background-color: var(--ant-primary-1);
    }
  }
}

.themed_filter_modal {
  height: 70vh;
  overflow-y: auto;
  &_item {
    margin: 5px 0;
    .label {
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 21px;
      margin-left: 0.5rem;
      color: var(--ant-primary-8);
    }
  }
}
.virtual_input {
  width: 176px !important;
  border: 0.5px solid #0c4292 !important;
  border-radius: 0.25rem !important;
  .ant-picker-input > input::placeholder {
    color: var(--ant-primary-color) !important;
  }
}
