.blank {
  width: 100%;
  height: 100vh;
  background: white;
  .loading {
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    transition: 1s;
    left: 0;
  }
  .loading > svg {
    // width: 15vw;
    color: red;
  }
  .loading > svg:nth-child(2) {
    // width: 25vw;
    margin-left: 2vw;
    color: red;
  }
  .loading > svg > path {
    stroke: #0c4292;
    stroke-width: 0.5;
    fill: transparent;
    // animation-duration: 0.3s;
    // animation-delay: 0;
    stroke-dasharray: 500;
    animation: draw 2s 0s ease-out infinite alternate forwards;
  }
  @keyframes draw {
    0% {
      fill: transparent;
      stroke-dashoffset: 660;
    }
    80% {
      fill: transparent;
      stroke-width: 0.5;
    }
    100% {
      stroke-width: 0;
      stroke-dashoffset: 0;
      fill: #0c4292;
    }
  }
}

.p_Anim {
  position: absolute;
  width: 100%;
  margin-top: 20vw;
  display: flex;
  justify-content: center;
}
.p_Anim_p1 {
  width: 70%;
  font-size: 5rem;
  font-weight: 900;
  position: relative;
  text-align: center;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text !important;
  background-image: linear-gradient(
    100deg,
    white,
    var(--primaryBlue),
    var(--primaryBlue),
    var(--primaryBlue),
    var(--primaryBlue),
    var(--primaryBlue),
    white
  );
  background-attachment: fixed;
  text-shadow: 0 0vw 0vw #ffffff10;
  background-position: center;
  background-size: 200vh;
  animation: p_Anim_p1 2s infinite;
}
@keyframes p_Anim_p1 {
  0% {
    background-position: 100px 0;
  }
  100% {
    background-position: 1300px 0;
  }
}
