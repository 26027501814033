.testInfoCont {
  padding: 1rem 1rem 0 1rem;

  .testInfoItemCont {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 21px;
      color: var(--ant-primary-color);
    }

    .select {
      width: 15rem;
      margin: 0.5rem 0;
    }

    .input {
      width: 20rem;
      margin: 0.5rem 0;
    }

    margin-right: 4rem;
  }

  border-bottom: 0.5px solid var(--cornflowerBlue);
}

.import_cont {
  padding: 1rem;

  .btn {
    margin-bottom: 0.5rem;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 14px;
    color: var(--tundora);

    span {
      color: var(--statusOffline);
    }
  }
}

.test_list {
  padding: 1rem;
  width: 100%;

  &_preview {
    margin-left: 2.5rem;

    &_label {
      margin-left: 0.5rem;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8rem;
      color: var(--black);
    }
  }

  .question {
    width: 100%;
    margin-top: 0.5rem;

    &_number {
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      line-height: 21px;
      min-width: 2.5rem;
      text-align: end;
      padding-right: 0.5rem;
      color: var(--ant-primary-color);
    }

    .ant-row {
      width: 100%;
    }
  }

  .answer {
    width: 100%;

    &_number {
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      line-height: 21px;
      min-width: 4.5rem;
      text-align: end;
      padding-right: 0.5rem;
      color: var(--ant-primary-color);
    }

    .ant-row {
      width: 100%;
    }
  }

  &_actions {
    margin-top: 0.5rem;

    &_item {
      text-align: center;
      &_check {
        display: flex;
        align-items: center;

        svg {
          width: 1rem;
          padding-top: 0.3rem;
        }
      }
      &_ok {
        font-size: 0.9rem !important;
        font-weight: bold !important;
        padding: 0 6px !important;
        color: var(--ant-primary-color) !important;
        background: var(--white) !important;
        border-color: var(--ant-primary-color) !important;
        &:hover {
          background: var(--white) !important;
          border-color: var(--ant-primary-color) !important;
        }
        &:focus {
          background: var(--white) !important;
          border-color: var(--ant-primary-color) !important;
        }
      }

      &_danger {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
  }

  &_actions_answer {
    &_item {
      text-align: center;
    }
  }

  .add_test_button {
    width: 80%;
    margin: 0 auto;
  }
}

.show_preview {
  border: 1px solid var(--black);
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
}

textarea.ant-input::-webkit-scrollbar {
  width: 0;
}

.empty_title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 26px;
  color: var(--ant-primary-color);
}

.image_popover {
  position: relative;
  &_close {
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: var(--scorpion);
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    svg {
      transform: translate(15%, 20%);
      path {
        fill: var(--ant-error-color-deprecated-bg);
      }
    }
  }
}

.questionsView {
  p.trueAnswer {
    color: green;
    font-weight: 700;
  }
  p {
    margin-left: 1rem;
    margin-bottom: 0.6rem;
  }
  p.que {
    font-weight: 700;
    margin-left: 0;
    margin-bottom: 0.2rem;
    margin-top: 0.6rem;
  }
}
