.tableBlock {
  .green_btn {
    margin: 0 auto;
  }
  .edit_test {
    margin: 0 auto;
  }
  .ant-table-row {
    > td {
      padding-left: 2rem;
    }
  }
  .ant-table-thead > tr > th {
    padding-left: 2rem;
  }
  .more {
    &_icon {
      margin: 0 auto;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      transition: 0.2s;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
      &:hover {
        background: var(--hoverLightBlue);
      }
    }
  }
}
